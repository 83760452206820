<template>
    <vx-card :title="'Edit Simple Stock Transfer Order Delivery Order'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>STO Doc. Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly && id">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>STO DO Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="codeDO" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    :value="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.code + ' ' + this.selectedSrcWarehouse.name : ''"
                    :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    :value="(this.selectedDestWarehouse) ? this.selectedDestWarehouse.code + ' ' + this.selectedDestWarehouse.name : ''"
                    :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Delivery Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date :value="this.deliveryDate" @selected="(val) => {
                    this.deliveryDate = val
                }"></Date>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea v-model="notes" width="100%" @input="checkCharacterCount" />
                <div>{{ characterCount }}/255</div>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Attachment</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <input type="file" ref="attechment" accept=".jpg,.jpeg, .png" @change="handleChangeAttachment" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="this.fileUrl != ''">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <div class="op-block mt-5">
                    <vs-list>
                        <vs-list-item title="File 1">
                            <div class="vx-input-group flex">
                                <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                    title="Download" class="mr-1" @click="openTab(fileUrl)" />
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="50%" style="vertical-align: middle;" rowspan="2">SKU</th>
                            <th width="15%" style="text-align: center;" colspan="2">STO Qty</th>
                            <th width="5%" style="text-align: center;"></th>
                            <th width="25%" style="text-align: center;" colspan="3">DO Qty</th>
                            <th width="5%" style="vertical-align: middle;" rowspan="2"></th>
                        </tr>
                        <tr>
                            <th width="10%">HU</th>
                            <th width="5%">Qty</th>
                            <th width="5%"></th>
                            <th width="10%">HU</th>
                            <th width="5%">Qty</th>
                            <th width="10%">Batch #</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(skuLine, index) in skuLines">
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].do_lines.length">
                                    <vs-input :value="(skuLine.selected_sku) ? skuLine.selected_sku.label : ''"
                                        :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].do_lines.length">
                                    <vs-input :value="skuLine.selected_hu" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].do_lines.length">
                                    <vs-input :value="skuLine.qty" :readonly="true" class="w-full"></vs-input>
                                </td>

                                <td :rowspan="skuLines[index].do_lines.length"></td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select :filterable="false" :options="skuLine.option_hu"
                                        v-model="skuLines[index].do_lines[0].selected_hu"
                                        @input="(val) => { onChangeHu(val, index, 0); }" class="w-full" />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input type="number" v-model="skuLines[index].do_lines[0].qty"
                                        class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input type="text" v-model="skuLines[index].do_lines[0].batch"
                                        class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button v-if="skuLines[index].do_lines.length > 1"
                                            @click.stop="removeRow(index, 0)" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                            title="Remove Row" />
                                        <vs-button @click.stop="addRow(index)" size="small" color="success"
                                            v-if="skuLines[index].do_lines.length == 1" icon-pack="feather" icon="icon-plus"
                                            title="Add Row" />
                                    </div>
                                </td>
                            </tr>

                            <template v-for="(doLine, indexDoLine) in skuLines[index].do_lines">
                                <tr style="padding-bottom: 5px" :key="index + '-' + indexDoLine" v-if="indexDoLine > 0">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <v-select :filterable="false" :options="skuLine.option_hu"
                                            v-model="skuLines[index].do_lines[indexDoLine].selected_hu"
                                            @input="(val) => { onChangeHu(val, index, indexDoLine); }" class="w-full" />
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input type="number" v-model="skuLines[index].do_lines[indexDoLine].qty"
                                            class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input type="text" v-model="skuLines[index].do_lines[indexDoLine].batch"
                                            class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td">
                                        <div class="vx-input-group flex mx-auto">
                                            <vs-button v-if="skuLines[index].do_lines.length > 0"
                                                @click.stop="removeRow(index, indexDoLine)" size="small" color="danger"
                                                icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                                title="Remove Row" />
                                            <vs-button @click.stop="addRow(index)" size="small" color="success"
                                                v-if="skuLines[index].do_lines.length == indexDoLine + 1"
                                                icon-pack="feather" icon="icon-plus" title="Add Row" />
                                        </div>
                                    </td>

                                </tr>

                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit" :disabled="characterCount > 255">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import Warehouse from "./component/Warehouse.vue";
import Date from "./component/Date.vue";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        Warehouse,
        Date,
        vSelect
    },
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            codeDO: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            deliveryDate: null,
            eta: null,
            notes: null,
            characterCount: 0,
            skuLines: [
                {
                    stock_relocation_plan_line_id: 0,
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                    do_lines: [{
                        selected_hu: null,
                        qty: 0,
                        batch: "",
                    }]
                }
            ],

            file: null,
            fileUrl: '',
        }
    },
    mounted() {
        var now = moment()

        this.deliveryDate = now.toDate()

        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.$store.state.outbound.simpleStoDo.baseUrlPath + "/" + this.id)
                .then((resp) => {
                    console.log(resp)
                    if (resp.code == 200) {
                        this.code = resp.data.code
                        this.codeDO = resp.data.code_do
                        this.selectedSrcWarehouse = resp.data.selected_src_warehouse
                        this.selectedDestWarehouse = resp.data.selected_dest_warehouse
                        this.deliveryDate = moment(resp.data.delivery_date).toDate()
                        this.eta = moment(resp.data.eta).toDate()
                        this.notes = resp.data.notes
                        this.skuLines = resp.data.sku_lines

                        this.fileUrl = resp.data.file_url
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setDefault() {
            this.id = null
            this.code = null
            this.do_code = null
            this.deliveryDate = null
            this.notes = null
            this.skuLines = [
                {
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                    do_lines: [{
                        selected_hu: null,
                        qty: 0,
                        batch: "",
                    }]
                }
            ]
            this.file = null
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.outbound.simpleStoDo.baseRouterName,
            });
        },
        handleChangeAttachment(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        handleSubmit() {
            if (!this.deliveryDate) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Delivery Date is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let message = ""
            let skus = []
            this.skuLines.forEach((val) => {
                let doLines = []
                val.do_lines.forEach((valDoLine) => {
                    // if (val.selected_hu == null || val.selected_hu == "") {
                    //     message = "please check hu, hu is required"
                    //     return
                    // }
                    // if (valDoLine.qty < 1) {
                    //     message = "qty is required more than zero"
                    //     return
                    // }


                    doLines.push({
                        item_unit: valDoLine.selected_hu,
                        quantity: parseInt(valDoLine.qty),
                        batch: valDoLine.batch
                    })
                })

                skus.push({
                    stock_relocation_plan_line_id: val.stock_relocation_plan_line_id,
                    item_id: val.selected_sku.id,
                    item_unit: val.selected_hu,
                    quantity: parseInt(val.qty),
                    do_lines: doLines,
                })
            })

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("delivery_date", this.deliveryDate
                ? moment(this.deliveryDate).format("YYYY-MM-DD")
                : '')
            formData.append("notes", (this.notes) ? this.notes : "")
            formData.append("skus", JSON.stringify(skus))

            this.$vs.loading();

            let http = this.$http
                .put(this.$store.state.outbound.simpleStoDo.baseUrlPath + "/" + this.id, formData)

            http.then((resp) => {
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    this.$vs.loading.close();
                    this.draw++;
                    this.setDefault();
                    this.handleBack()
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        onChangeHu(val, index, indexDoLine) {
            this.skuLines[index].do_lines[indexDoLine].selected_hu = val
        },
        removeRow(index, indexDoLine) {
            this.skuLines[index].do_lines.splice(indexDoLine, 1);
        },
        addRow(index) {
            this.skuLines[index].do_lines.push({
                selected_hu: null,
                qty: 0,
                batch: "",
            });
        },
        openTab(uri) {
            window.open(uri)
        },
        checkCharacterCount() {
            this.characterCount = this.notes.length;
        },
    },
    watch: {

    }
}
</script>